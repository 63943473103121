<template>
  <div>
    <div id="home">
      <section class="block">
        <div class="columns">
          <div class="column is-5-desktop is-8-tablet-only">
            <div class="content">
              <img id="logo" :src="logo_img" />
              <p>
                我们是一家致力于将人工智能技术应用于传统软件的公司，在海量数据人脸识别、无人机视觉、最优化算法等技术方面有所积累，同时热衷于传统的前后端技术。
              </p>
            </div>
          </div>
          <div
            class="column is-hidden-mobile is-7-desktop is-4-tablet-only"
            id="cover"
          ></div>
        </div>
      </section>
      <section class="block">
        <div class="block">
          <h1 class="subtitle is-3" id="intro_title">案例展示</h1>
        </div>
        <div class="columns" id="intro">
          <div class="column">
            <div id="card1">
              <a class="card-image" href="https://donglicang.cn/troll">
                <img :src="troll_img" />
              </a>
              <div class="card-content">
                海量数据人脸自动识别与分类系统，前后端分离架构，特别适合部署在服务器及NAS上。<a
                  href="https://donglicang.cn/troll"
                  >demo>></a
                >
              </div>
            </div>
          </div>
          <div class="column">
            <div id="card2">
              <a class="card-image" @click="toIPM">
                <img :src="ipm_img" />
              </a>
              <div class="card-content">
                振动落点定位软件，融合多振动传感器数据，采用先进的滤波和优化算法预测振动位置。
                <a @click="toIPM">详情>></a>
              </div>
            </div>
          </div>
          <div class="column">
            <div id="card3">
              <a
                class="card-image"
                href="https://donglicang.cn/shop"
                target="_blank"
              >
                <img :src="shop_img" />
              </a>
              <div class="card-content">
                基于小程序/springboot/vue技术构建
                ，支持单店铺、多店铺入驻的商城平台。
                <a href="https://donglicang.cn/shop" target="_blank">demo>></a>
              </div>
            </div>
          </div>
          <div class="column">
            <div id="card4">
              <a class="card-image" onclick="alert('建设中...')">
                <img :src="drone_img" />
              </a>
              <div class="card-content">
                无人机视觉技术，目标识别、SLAM应用。<a
                  onclick="alert('建设中...')"
                  >详情>></a
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="block" id="footer_margin"></section>
    </div>
    <div id="footer">
      <span class="icon-text block">
        <span class="icon">
          <img src="./assets/tel.png" />
        </span>
        <span style="margin-right: 1rem">18612868346</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" class="icp"
          >京ICP备2021025361号</a
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      logo_img: require("./assets/logo.png"),
      troll_img: require("./assets/augmented-reality@1x.svg"),
      ipm_img: require("./assets/virtual-reality-1@1x.svg"),
      shop_img: require("./assets/money@1x.svg"),
      drone_img: require("./assets/drone-2@1x.svg"),
    };
  },
  methods: {
    toIPM() {
      console.log("toIPM");
      this.$router.push({ name: "IPM" });
    },
  },
};
</script>

<style>
#home {
  margin-top: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
#cover {
  background-position-x: 100%;
  background-image: url("~@/assets/psketch.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  /* margin: 1rem; */
  min-height: 400px;
}
#logo {
  margin-bottom: 1rem;
  width: 50%;
}
.content {
  text-align: left;
  border-style: none;
  border-radius: 1rem;
  /* padding: 1rem; */
}
.content p {
  text-align: justify;
  line-height: 1.5;
  font-size: 22px;
}
#intro_title {
  text-align: left;
  padding-left: 1rem;
}
.card-image {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}
.card-content {
  text-align: left;
}
#card1:hover {
  box-shadow: 0.1rem 0.1rem 0.5rem hsl(171, 100%, 41%);
  border-radius: 1rem;
}
#card2:hover {
  box-shadow: 0.1rem 0.1rem 0.5rem gray;
  border-radius: 1rem;
}
#card3:hover {
  box-shadow: 0.1rem 0.1rem 0.5rem red;
  border-radius: 1rem;
}
#card4:hover {
  box-shadow: 0.1rem 0.1rem 0.5rem blue;
  border-radius: 1rem;
}
#footer_margin {
  height: 3rem;
}
#footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  color: white;
  background-color: gray;
  font-size: 1rem;
  padding-right: 2rem;
  padding: 1rem;
  text-align: right;
}
a.icp:link,
:visited {
  color: white;
}
</style>
