import Vue from 'vue'
import App from './App.vue'
import 'bulma/css/bulma.css'
import 'font-awesome/css/font-awesome.css'
import VueRouter from 'vue-router'
import IPM from './IPM.vue'
import Home from './Home.vue'

Vue.use(VueRouter)

Vue.config.productionTip = false

const routes = [
  { path: '/IPM', component: IPM, name: 'IPM' },
  { path: '/Home', component: Home, name: 'Home' }
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
router.push('/Home')

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
