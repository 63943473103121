<template>
  <div>
    <router-link to="/Home" id="backhome" class="button is-rounded"
      >返回</router-link
    >
    <h5 class="title is-5" id="title">振动落点定位软件</h5>
    <div id="ipm">
      <section class="section">
        <div class="columns">
          <div class="column is-3">
            <h6 class="title is-6">功能介绍</h6>
            <p>
              在某区域内布置若干个振动传感器，在该区域内某时刻某处产生了振动源，以地质为媒介传播到各探测器，由于各探测器离振源距离不同，所以接收到振动波的时间也不同，因此可以通过各探测器的时间差计算出振动源所在位置。
            </p>
          </div>
          <div class="column">
            <img src="./assets/IPM@1x.png" style="zoom: 40%" />
          </div>
        </div>

        <div class="columns">
          <div class="column is-3">
            <h6 class="title is-6">软件界面</h6>
          </div>
          <div class="column"><img src="./assets/wps3154.tmp.jpg" /></div>
        </div>

        <div class="columns">
          <div class="column is-3">
            <h6 class="title is-6">探测器振动波形总览</h6>
          </div>
          <div class="column"><img src="./assets/wps3155.tmp.jpg" /></div>
        </div>

        <div class="columns">
          <div class="column is-3">
            <h6 class="title is-6">振动波冲击时刻自动提取</h6>
          </div>
          <div class="column"><img src="./assets/wps3156.tmp.jpg" /></div>
        </div>

        <div class="columns">
          <div class="column is-3">
            <h6 class="title is-6">
              SSA扫描法计算结果图（颜色越偏蓝说明越靠近落点）
            </h6>
          </div>
          <div class="column">
            <img src="./assets/wps3166.tmp.jpg" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "IPM",
};
</script>

<style scoped>
#backhome {
  position: fixed;
  right: 2rem;
  top: 0.5rem;
  box-shadow: 0.01rem 0 0.2rem orangered;
  background-color: orangered;
  color: white;
  z-index: 1;
}
#title {
  position: fixed;
  padding: 1rem;
  width: 100%;
  text-align: left;
  box-shadow: 0.01rem 0 0.2rem gray;
  background-color: gray;
  color: white;
}
#ipm {
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
p,
h6 {
  text-align: justify;
  line-height: 1.5;
}
</style>

